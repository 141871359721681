export const RouteNames = {
  landingPage: "/",
  login: "/login",
  register: "/register",
  createAccount: "/create-account",
  verifyMail: "/verifymail",
  resetPassword: "/resetPassword",
  reportIssue: "/ReportIssue",
  ticketDetails: "/TicketDetails",
  dashboard: "/Dashboard",
  addNewTradesman: "/AddNewTradesperson",
  viewAllTradesman: "/ViewAllTradesperson",
  addNewListing: "/AddNewProperty",
  viewAllListings: "/ViewAllProperties",
  viewListingDetails: "/ViewAllProperties",
  viewTradespersonDetails: "/viewTradespersonDetails",
  addNewAnnouncement: "/AddNewAnnouncement",
  viewAllAnnouncements: "/ViewAllAnnouncements",
  viewAllTAnnouncements: "/ViewAllTAnnouncements",
  allTickets: "/AllTickets",
  feedback: "/Feedback",
  rejectJob: "/JobStatus",
  JobCompletion: "/JobCompletion",
  jobStatus: "/TicketStatus",
  complaince: "/Compliance",
  addCompliance: "/AddCompliance",
  test: "/test",
  settings: "/Settings/AccountSettings",
  myDetails: "/Settings/MyDetails",
  support: "/support",
  messages: "/MyMessages",
  myLanlord: "/MyLandLord",
  subscription: "/Subscription",
  adminUsers: "/AdminUsers",
  adminAdd: "/AdminAdd",
  tags: "/tags",
  addTag: "/addTag",
  adminView: "/viewAdminDetails",
  loginPasswordChange: "/passwordChange",
  checklist: "/checklist"
};
