export const GET_CHECKLIST_REQUEST = "GET_CHECKLIST_REQUEST";
export const GET_CHECKLIST_FAIL = "GET_CHECKLIST_FAIL";
export const GET_CHECKLIST_SUCCESS = "GET_CHECKLIST_SUCCESS";
export const ADD_CHECKLIST_ITEM_REQUEST = "ADD_CHECKLIST_ITEM_REQUEST";
export const ADD_CHECKLIST_ITEM_FAIL = "ADD_CHECKLIST_ITEM_FAIL";
export const ADD_CHECKLIST_ITEM_SUCCESS = "ADD_CHECKLIST_ITEM_SUCCESS";
export const DELETE_CHECKLIST_ITEM_REQUEST = "DELETE_CHECKLIST_ITEM_REQUEST";
export const DELETE_CHECKLIST_ITEM_FAIL = "DELETE_CHECKLIST_ITEM_FAIL";
export const DELETE_CHECKLIST_ITEM_SUCCESS = "DELETE_CHECKLIST_ITEM_SUCCESS";
export const UPDATE_SHARED_STATUS_REQUEST = "UPDATE_SHARED_STATUS_REQUEST";
export const UPDATE_SHARED_STATUS_FAIL = "UPDATE_SHARED_STATUS_FAIL";
export const UPDATE_SHARED_STATUS_SUCCESS = "UPDATE_SHARED_STATUS_SUCCESS";
export const UPDATE_DEFAULT_STATUS_REQUEST = "UPDATE_DEFAULT_STATUS_REQUEST";
export const UPDATE_DEFAULT_STATUS_FAIL = "UPDATE_DEFAULT_STATUS_FAIL";
export const UPDATE_DEFAULT_STATUS_SUCCESS = "UPDATE_DEFAULT_STATUS_SUCCESS";
export const EMPTY_CHECKLIST = "EMPTY_CHECKLIST";
export const REORDER_CHECKLIST_REQUEST = "REORDER_CHECKLIST_REQUEST";
export const REORDER_CHECKLIST_FAIL = "REORDER_CHECKLIST_FAIL";
export const REORDER_CHECKLIST_SUCCESS = " REORDER_CHECKLIST_SUCCESS";
