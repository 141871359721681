import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import reducers from "../reducers";
import userReducers from "../redux/user/userReducers";
import snackbarReducer from "../redux/snackbar/snackbarReducers";
import loaderReducer from "../redux/loader/loaderReducers";
import fullScreenReducer from "../redux/full-screen-modal/fullScreenModalReducers";
import errorReducer from "../redux/errors/errorReducer";
import dashboardReducer from "../redux/dashboard/reducer";
import propertyReducer from "../redux/property/propertyReducers";
import compliancesReducer from "../redux/compliance/reducer";
import categoryReducer from "../redux/category/reducer";
import messagesReducer from "redux/chat/reducer";
import tagReducer from "redux/Tags/tagReducer";
import checklistReducer from "redux/checklist/reducer";

const appReducer = combineReducers({
  ...reducers,
  user: userReducers,
  snackbar: snackbarReducer,
  loader: loaderReducer,
  fullScreenModal: fullScreenReducer,
  error: errorReducer,
  dashboard: dashboardReducer,
  property: propertyReducer,
  compliance: compliancesReducer,
  category: categoryReducer,
  messages: messagesReducer,
  tag: tagReducer,
  checklist: checklistReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const composeEnhancers = composeWithDevTools({});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
