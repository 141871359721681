import {
  GET_CHECKLIST_FAIL,
  GET_CHECKLIST_REQUEST,
  GET_CHECKLIST_SUCCESS,
  ADD_CHECKLIST_ITEM_REQUEST,
  ADD_CHECKLIST_ITEM_SUCCESS,
  ADD_CHECKLIST_ITEM_FAIL,
  DELETE_CHECKLIST_ITEM_REQUEST,
  DELETE_CHECKLIST_ITEM_FAIL,
  UPDATE_SHARED_STATUS_FAIL,
  UPDATE_SHARED_STATUS_REQUEST,
  UPDATE_SHARED_STATUS_SUCCESS,
  UPDATE_DEFAULT_STATUS_FAIL,
  UPDATE_DEFAULT_STATUS_REQUEST,
  UPDATE_DEFAULT_STATUS_SUCCESS,
  EMPTY_CHECKLIST,
  REORDER_CHECKLIST_FAIL,
  REORDER_CHECKLIST_REQUEST,
  REORDER_CHECKLIST_SUCCESS
} from "./types";

const initialState = {
  checklist: [],
  loading: false,
  success: false,
  error: "",
  actionItemId: ""
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKLIST_REQUEST:
      return {
        ...state,
        loading: true,
        success: false
      };
    case GET_CHECKLIST_FAIL:
    case ADD_CHECKLIST_ITEM_FAIL:
    case DELETE_CHECKLIST_ITEM_FAIL:
    case UPDATE_SHARED_STATUS_FAIL:
    case UPDATE_DEFAULT_STATUS_FAIL:
    case REORDER_CHECKLIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        actionItemId: ""
      };
    case GET_CHECKLIST_SUCCESS:
    case ADD_CHECKLIST_ITEM_SUCCESS:
    case REORDER_CHECKLIST_SUCCESS:
      return {
        ...state,
        checklist: [...action.payload.checklist],
        success: true,
        loading: false
      };
    case ADD_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        success: false
      };
    case DELETE_CHECKLIST_ITEM_REQUEST: {
      let checklist = state.checklist.filter(({ _id }) => _id !== action.payload);
      return {
        ...state,
        checklist
      };
    }
    case UPDATE_SHARED_STATUS_REQUEST:
    case UPDATE_DEFAULT_STATUS_REQUEST:
      return {
        ...state,
        actionItemId: action.payload
      };
    case UPDATE_SHARED_STATUS_SUCCESS:
      return {
        ...state,
        actionItemId: ""
      };
    case UPDATE_DEFAULT_STATUS_SUCCESS:
      return {
        ...state,
        checklist: state.checklist.map((item) => (item._id === action.payload ? { ...item, defaultState: !item.defaultState } : item)),
        actionItemId: ""
      };
    case REORDER_CHECKLIST_REQUEST:
      return {
        ...state,
        loading: true,
        checklist: []
      };
    case EMPTY_CHECKLIST:
      return {
        ...state,
        checklist: []
      };
    default:
      return state;
  }
};

export default checklistReducer;
